import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/home/home.component').then((c) => c.HomeComponent),
  },
  {
    path: 'by',
    loadComponent: () =>
      import('./pages/home/home.component').then((c) => c.HomeComponent),
  },
  {
    path: 'en',
    loadComponent: () =>
      import('./pages/home/home.component').then((c) => c.HomeComponent),
  },
  {
    path: 'ua',
    loadComponent: () =>
      import('./pages/home/home.component').then((c) => c.HomeComponent),
  },

  {
    path: 'for-companies',
    loadComponent: () =>
      import('./pages/for-companies-page/for-companies-page.component').then(
        (c) => c.ForCompaniesPageComponent
      ),
  },
  {
    path: 'by/for-companies',
    loadComponent: () =>
      import('./pages/for-companies-page/for-companies-page.component').then(
        (c) => c.ForCompaniesPageComponent
      ),
  },
  {
    path: 'en/for-companies',
    loadComponent: () =>
      import('./pages/for-companies-page/for-companies-page.component').then(
        (c) => c.ForCompaniesPageComponent
      ),
  },
  {
    path: 'ua/for-companies',
    loadComponent: () =>
      import('./pages/for-companies-page/for-companies-page.component').then(
        (c) => c.ForCompaniesPageComponent
      ),
  },

  {
    path: 'blog',
    loadComponent: () =>
      import('./pages/blog-page/blog-page.component').then(
        (c) => c.BlogPageComponent
      ),
  },
  {
    path: 'by/blog',
    loadComponent: () =>
      import('./pages/blog-page/blog-page.component').then(
        (c) => c.BlogPageComponent
      ),
  },
  {
    path: 'en/blog',
    loadComponent: () =>
      import('./pages/blog-page/blog-page.component').then(
        (c) => c.BlogPageComponent
      ),
  },
  {
    path: 'ua/blog',
    loadComponent: () =>
      import('./pages/blog-page/blog-page.component').then(
        (c) => c.BlogPageComponent
      ),
  },

  {
    path: 'blog/:postslug',
    loadComponent: () =>
      import('./pages/post-page/post-page.component').then(
        (c) => c.PostPageComponent
      ),
  },
  {
    path: 'by/blog/:postslug',
    loadComponent: () =>
      import('./pages/post-page/post-page.component').then(
        (c) => c.PostPageComponent
      ),
  },
  {
    path: 'en/blog/:postslug',
    loadComponent: () =>
      import('./pages/post-page/post-page.component').then(
        (c) => c.PostPageComponent
      ),
  },
  {
    path: 'ua/blog/:postslug',
    loadComponent: () =>
      import('./pages/post-page/post-page.component').then(
        (c) => c.PostPageComponent
      ),
  },

  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./pages/privacy-policy/privacy-policy.component').then(
        (c) => c.PrivacyPolicyComponent
      ),
  },
  {
    path: 'by/privacy-policy',
    loadComponent: () =>
      import('./pages/privacy-policy/privacy-policy.component').then(
        (c) => c.PrivacyPolicyComponent
      ),
  },
  {
    path: 'en/privacy-policy',
    loadComponent: () =>
      import('./pages/privacy-policy/privacy-policy.component').then(
        (c) => c.PrivacyPolicyComponent
      ),
  },
  {
    path: 'ua/privacy-policy',
    loadComponent: () =>
      import('./pages/privacy-policy/privacy-policy.component').then(
        (c) => c.PrivacyPolicyComponent
      ),
  },

  {
    path: 'cookie-policy',
    loadComponent: () =>
      import('./pages/cookie-policy/cookie-policy.component').then(
        (c) => c.CookiePolicyComponent
      ),
  },
  {
    path: 'by/cookie-policy',
    loadComponent: () =>
      import('./pages/cookie-policy/cookie-policy.component').then(
        (c) => c.CookiePolicyComponent
      ),
  },
  {
    path: 'en/cookie-policy',
    loadComponent: () =>
      import('./pages/cookie-policy/cookie-policy.component').then(
        (c) => c.CookiePolicyComponent
      ),
  },
  {
    path: 'ua/cookie-policy',
    loadComponent: () =>
      import('./pages/cookie-policy/cookie-policy.component').then(
        (c) => c.CookiePolicyComponent
      ),
  },

  {
    path: 'terms-of-service',
    loadComponent: () =>
      import('./pages/terms/terms.component').then((c) => c.TermsComponent),
  },
  {
    path: 'by/terms-of-service',
    loadComponent: () =>
      import('./pages/terms/terms.component').then((c) => c.TermsComponent),
  },
  {
    path: 'en/terms-of-service',
    loadComponent: () =>
      import('./pages/terms/terms.component').then((c) => c.TermsComponent),
  },
  {
    path: 'ua/terms-of-service',
    loadComponent: () =>
      import('./pages/terms/terms.component').then((c) => c.TermsComponent),
  },

  {
    path: '404',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then(
        (c) => c.NotFoundComponent
      ),
  },
  {
    path: 'by/404',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then(
        (c) => c.NotFoundComponent
      ),
  },
  {
    path: 'en/404',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then(
        (c) => c.NotFoundComponent
      ),
  },
  {
    path: 'ua/404',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then(
        (c) => c.NotFoundComponent
      ),
  },

  {
    path: '**',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then(
        (c) => c.NotFoundComponent
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 0],
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
